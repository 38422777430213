.statistics-company {
  position: relative;
  padding: 70px 0 50px;
  width: 100%;
  font-size: 16px;
  line-height: 26px;
  color: #8f8d8b;
  @include main-font8();
}
.statistics-company__layout {
  position: relative;
  z-index: 1;
  max-width: 1200px;
  margin: 0 auto;

  >div {
    margin: 0 0 50px;
    padding: 0 20px;

    &.highlighted
    {
      display: block;
      width: 100%;

      .statistics-company__item
      {
        width: 100%;
        margin: 0 auto;
        background-color: #df9f38;
        border-radius: 4px;
        padding: 35px 50px;
        margin-bottom: 40px;
        text-align: center;

        .statistics-company__pic
        {
          position: relative;
          margin-left: auto;
          margin-right: auto;
        }

        .statistics-company__name
        {
          color: #fff;
          >span
          {
            color: #fff;
          }
        }
      }
    }

    &:nth-child(2) {

      .statistics-company__pic {

        svg {
          width: 72px;
          height: 90px;
        }

      }

    }
    &:nth-child(3) {

      .statistics-company__pic {

        svg {
          width: 90px;
          height: 51px;
        }

      }

    }
    &:nth-child(4) {

      .statistics-company__pic {

        svg {
          width: 59px;
          height: 90px;
        }

      }

    }
    &:nth-child(5) {

      .statistics-company__pic {

        svg {
          width: 75px;
          height: 82px;
        }

      }

    }

  }

}
.statistics-company__name {
  font-size: 18px;
  line-height: 29px;
  color: #29251e;

  >span {
    display: block;
    font-size: 30px;
    line-height: 34px;
    color: #df9f38;
  }

}
.statistics-company__item {
  position: relative;
  padding-top: 14px;
  padding-left: 120px;
  min-height: 90px;
  max-width: 320px;
  margin: 0 auto;
}
.statistics-company__pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 90px;
  height: 90px;
  margin: 0 0 20px;

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    width: 100%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }

}
.statistics-company-title{
  width: 100%;
  margin: 0 0 65px;
  font-size: 32px;
  line-height: 40px;
  color: #29251e;
  font-family: "Nexa Bold", sans-serif;
  font-weight: 300;
  font-style: normal;
  text-align: center;
}

@media (min-width: $tablet-portrait) {

  .statistics-company__layout {
    @include flexbox();
    @include flex-wrap(wrap);
    @include flex-direction(row);
    @include align-items(flex-start);
    @include justify-content(center);


    >div {
      &.highlighted
      {
        .statistics-company__item
        {
          width: 50%;
        }
      }
      width: 50%;
      padding: 0 20px;

    }
  }
  .statistics-company__item {
    padding: 0;
    max-width: 100%;
    margin: 0;
    text-align: center;
  }
  .statistics-company__pic {
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    height: 90px;
    margin: 0 0 37px;
  }

}
@media (min-width: $desktop) {

  .statistics-company {
    padding: 0;
  }
  .statistics-company__layout {
    padding: 119px 0;

    >div {
      &.highlighted
      {
        .statistics-company__item
        {
          width: 25%;
        }
      }
      width: 25%;
      margin: 0;
    }
  }
  .statistics-company__name {

    >span {
      font-size: 36px;
      line-height: 38px;
    }

  }
  .statistics-company-title{
    font-size: 40px;
    line-height: 48px;
  }

}

