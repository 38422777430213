.platform {
  @include inline-flex();
  @include flex-direction(row);
  @include flex-wrap(wrap);
  @include align-items(center);
  @include justify-content(center);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 570px;
  padding-top: 150px;
  padding-bottom: 50px;
  width: 100%;
  color: #fff;
  @include main-font8();
  font-size: 22px;
  line-height: 26px;

  p {
    margin: 0 0 47px;
  }

}
.platform__layout {
  width: 100%;
  max-width: 1210px;
  margin: 0 auto;
  padding: 0 20px;
}
.platform__title {
  margin: 0 0 19px;
  font-size: 32px;
  line-height: 40px;
  @include main-font6();
}

@media (min-width: $desktop) {

  .platform {
    min-height: 650px;
    background-position: top center;
  }
  .platform__layout {
    padding-right: 46%;
  }
  .platform__title {
    font-size: 40px;
    line-height: 48px;
  }

}
@media (min-width: $desktop-wide) {

  .platform {
    min-height: 931px;
    padding-top: 150px;
    padding-bottom: 191px;
  }
  .platform__layout {
    padding-right: 32%;
  }

}


