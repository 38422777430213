.subscribe-us {
  @include inline-flex();
  @include flex-direction(row);
  @include flex-wrap(wrap);
  @include align-items(center);
  @include justify-content(center);
  padding: 80px 0;
  background: url("../img/subscribe-bg.jpg") no-repeat top center;
  background-size: cover;
  width: 100%;
  color: #fff;
  @include main-font8();
  font-size: 22px;
  line-height: 26px;

  p {
    margin: 0 0 47px;
  }

}
.subscribe-us__layout {
  width: 100%;
  max-width: 1210px;
  margin: 0 auto;
  padding: 0 20px;
}
.subscribe-us__title {
  margin: 0 0 35px;
  font-size: 36px;
  line-height: 40px;
  @include main-font9();
}

@media (min-width: $desktop) {

  .subscribe-us {
    padding: 147px 0 160px;
  }
  .subscribe-us__layout {
    @include flexbox();
    @include flex-direction(row);
    @include align-items(center);
    @include justify-content(flex-start);

    >div {
      width: 35%;
      text-align: right;

      &:first-child {
        width: 60%;
        padding: 11px 5% 0 0;
        text-align: left;
      }

    }

  }
  .subscribe-us__title {
    margin: 0 0 19px;
    font-size: 45px;
    line-height: 55px;
  }

}
@media (min-width: $desktop-wide) {

  .subscribe-us {
    //min-height: 931px;
    //padding-top: 150px;
    //padding-bottom: 191px;
  }
  .subscribe-us__layout {
    //padding-right: 32%;

    >div {
      width: 23%;
      text-align: left;

      &:first-child {
        width: 60%;
        padding: 11px 15% 0 0;
      }

    }

  }

}


